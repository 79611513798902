import {
  type ISettingsColor,
  createStylesParam,
  createStylesParams,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
  CLASSIC_EDITOR_X_PRESET_ID,
  CLASSIC_PRESET_ID,
  GRID_EDITOR_X_PRESET_ID,
  GRID_PRESET_ID,
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
  STRIP_EDITOR_X_PRESET_ID,
  STRIP_PRESET_ID,
} from '../../consts';
import {
  AlignmentOptions,
  ImageAndTextRatioOptions,
  ImagePositionOptions,
  ImageShapeOptions,
  ImageSizeOptions,
  ServiceListLayoutOptions,
} from '../../types/types';
import { mapNumberToPresetId } from '../../utils/settings/settings';
import type { VisibilityKey } from './hooks/types';
import {
  generateAllCssVars,
  getIsServiceImageVisibleFromTpaDate,
} from './styleParams-utils';

const getPresetId = <F extends Function>(getStyleParamValue: F) => {
  let preset = getStyleParamValue(presetId);

  if (typeof preset === 'number') {
    preset = mapNumberToPresetId(preset);
  }

  return preset;
};

type stringStyleParams<T extends string = string> = {
  fontStyleParam: boolean;
  value: T;
};
export const buildStringStyleParams = <T extends string = string>(
  value: T,
): stringStyleParams<T> => {
  return { fontStyleParam: false, value };
};

export const getStringStyleParamsValue = (
  stringStyleParamObject: stringStyleParams,
) => {
  return stringStyleParamObject?.value;
};

export enum IStylesParamsColorsKeys {
  widgetBackgroundColor = 'widgetBackgroundColor', // MULTI_OFFERINGS_BACKGROUND_COLOR
  widgetTitleColor = 'widgetTitleColor', // MULTI_OFFERINGS_TITLE_COLOR
  infoBackgroundColor = 'infoBackgroundColor', // BACKGROUND_COLOR
  borderColor = 'borderColor', // BORDER_COLOR
  filterTitleColor = 'filterTitleColor', // CATEGORY_NAME_COLOR
  filterSelectedIndicationColor = 'filterSelectedIndicationColor', // CATEGORY_SELECTED_UNDERLINE_COLOR
  filterSelectedTextColor = 'filterSelectedTextColor', // CATEGORY_SELECTED_TEXT_COLOR
  filterSelectedBorderColor = 'filterSelectedBorderColor', // CATEGORY_SELECTED_BORDER_COLOR
  filterHoverIndicationColor = 'filterHoverIndicationColor', // CATEGORY_HOVER_BACKGROUND_COLOR
  filterHoverTextColor = 'filterHoverTextColor', // CATEGORY_HOVER_TEXT_COLOR
  filterHoverBorderColor = 'filterHoverBorderColor', // CATEGORY_HOVER_BORDER_COLOR
  filterBorderColor = 'filterBorderColor', // CATEGORY_DIVIDER_COLOR
  filterBackgroundColor = 'filterBackgroundColor', // CATEGORY_BACKGROUND_COLOR
  dropdownTextColor = 'dropdownTextColor', // MOBILE_CATEGORY_NAME_COLOR
  dropdownBorderColor = 'dropdownBorderColor', // MOBILE_CATEGORY_BORDER_COLOR
  dropdownBackgroundColor = 'dropdownBackgroundColor', // MOBILE_CATEGORY_BACKGROUND_COLOR
  serviceImageBackgroundColor = 'serviceImageBackgroundColor', // IMAGE_BACKGROUND_COLOR
  servicesDividerColor = 'servicesDividerColor', // SERVICE_DIVIDER_COLOR
  serviceDetailsColor = 'serviceDetailsColor', // OFFERING_DETAILS_COLOR
  courseAvailabilityColor = 'courseAvailabilityColor', // COURSE_AVAILABILITY_COLOR
  serviceNameColor = 'serviceNameColor', // OFFERING_NAME_COLOR
  serviceTaglineColor = 'serviceTaglineColor', // OFFERING_TAGLINE_COLOR
  moreInfoButtonColor = 'moreInfoButtonColor', // OFFERING_MORE_INFO_LABEL_COLOR
  serviceDividerColor = 'serviceDividerColor', // DIVIDER_COLOR
  bookButtonBackgroundColor = 'bookButtonBackgroundColor', // BUTTON_BACKGROUND_COLOR
  holeBookButtonTextColor = 'holeBookButtonTextColor', // BUTTON_TEXT_COLOR_HOLE
  fillBookButtonTextColor = 'fillBookButtonTextColor', // BUTTON_TEXT_COLOR_FILL
  bookButtonBorderColor = 'bookButtonBorderColor', // BUTTON_BORDER_COLOR
  bookButtonHoverBackgroundColor = 'bookButtonHoverBackgroundColor',
  bookButtonHoverBorderColor = 'bookButtonHoverBorderColor',
  bookButtonHoverTextColor = 'bookButtonHoverTextColor',
  explorePlansTextColor = 'explorePlansTextColor', // EXPLORE_PLANS_TEXT_COLOR
  fillLoadServicesButtonTextColor = 'fillLoadServicesButtonTextColor', // LOAD_SERVICES_BUTTON_TEXT_COLOR_FILL
  loadServicesButtonBackgroundColor = 'loadServicesButtonBackgroundColor', // LOAD_SERVICES_BUTTON_BACKGROUND_COLOR
  loadServicesButtonBorderColor = 'loadServicesButtonBorderColor', // LOAD_SERVICES_BUTTON_BORDER_COLOR
}

export enum IStylesParamsFontsKeys {
  widgetTitleFont = 'widgetTitleFont', // MULTI_OFFERINGS_TITLE_FONT
  filterTitleFont = 'filterTitleFont', // CATEGORY_NAME_FONT
  dropdownTextFont = 'dropdownTextFont', // MOBILE_CATEGORY_NAME_FONT
  serviceNameFont = 'serviceNameFont', // OFFERING_NAME_FONT
  courseAvailabilityFont = 'courseAvailabilityFont', // COURSE_AVAILABILITY_FONT
  serviceTagLineFont = 'serviceTagLineFont', // OFFERING_TAGLINE_FONT
  moreInfoButtonFont = 'moreInfoButtonFont', // OFFERING_MORE_INFO_LABEL_FONT
  serviceDetailsFont = 'serviceDetailsFont', // OFFERING_DETAILS_FONT
  bookButtonFont = 'bookButtonFont', // BUTTON_TEXT_FONT
  explorePlansFont = 'explorePlansFont', // EXPLORE_PLANS_FONT
  loadServicesButtonFont = 'loadServicesButtonFont', // LOAD_SERVICES_BUTTON_FONT
  serviceImageSize = 'serviceImageSize',
  serviceTextAlignment = 'serviceTextAlignment',
  titleAlignment = 'titleAlignment',
  serviceImagePosition = 'serviceImagePosition',
  serviceImageShape = 'serviceImageShape',
  serviceListLayout = 'serviceListLayout',
}

enum ILegacyStylesParamsFontsKeys {
  OFFERING_NAME_FONT = 'OFFERING_NAME_FONT',
  CATEGORY_NAME_FONT = 'CATEGORY_NAME_FONT',
  MULTI_OFFERINGS_TITLE_FONT = 'MULTI_OFFERINGS_TITLE_FONT',
  MOBILE_CATEGORY_NAME_FONT = 'MOBILE_CATEGORY_NAME_FONT',
  COURSE_AVAILABILITY_FONT = 'COURSE_AVAILABILITY_FONT',
  OFFERING_TAGLINE_FONT = 'OFFERING_TAGLINE_FONT',
  OFFERING_MORE_INFO_LABEL_FONT = 'OFFERING_MORE_INFO_LABEL_FONT',
  OFFERING_DETAILS_FONT = 'OFFERING_DETAILS_FONT',
  BUTTON_TEXT_FONT = 'BUTTON_TEXT_FONT',
  EXPLORE_PLANS_FONT = 'EXPLORE_PLANS_FONT',
}

export enum IStylesParamsNumbersKeys {
  mobileWidgetTitleFontSize = 'mobileWidgetTitleFontSize', // MOBILE_MULTI_OFFERINGS_TITLE_FONT_SIZE
  borderWidth = 'borderWidth', // BORDER_WIDTH
  cornerRadius = 'cornerRadius', // CORNER_RADIUS
  mobileFilterTitleFontSize = 'mobileFilterTitleFontSize', // MOBILE_CATEGORY_NAME_FONT_SIZE
  filterBorderWidth = 'filterBorderWidth', // CATEGORY_BORDER_WIDTH
  filterBorderRadius = 'filterBorderRadius', // CATEGORY_BORDER_RADIUS
  filterSpacing = 'filterSpacing', // CATEGORY_SPACING
  dropdownBorderWidth = 'dropdownBorderWidth', // MOBILE_CATEGORY_BORDER_WIDTH
  overlappingSidePadding = 'overlappingSidePadding', // OVERLAPPING_SIDE_PADDING
  overlappingVerticalPadding = 'overlappingVerticalPadding', // OVERLAPPING_VERTICAL_PADDING
  stripBorderWidth = 'stripBorderWidth', // STRIP_BORDER_WIDTH
  stripSidePadding = 'stripSidePadding', // STRIP_SIDE_PADDING
  stripVerticalPadding = 'stripVerticalPadding', // STRIP_VERTICAL_PADDING, MOBILE_STRIP_VERTICAL_PADDING
  imageAndTextRatio = 'imageAndTextRatio', // IMAGE_AND_TEXT_RATIO
  mobileServiceNameFontSize = 'mobileServiceNameFontSize', // MOBILE_OFFERING_NAME_FONT_SIZE
  mobileServiceTaglineFontSize = 'mobileServiceTaglineFontSize', // MOBILE_OFFERING_TAGLINE_FONT_SIZE
  mobileMoreInfoButtonFontSize = 'mobileMoreInfoButtonFontSize', // MOBILE_MORE_INFO_LABEL_FONT_SIZE
  serviceDividerWidth = 'serviceDividerWidth', // DIVIDER_WIDTH
  classicSidePadding = 'classicSidePadding', // CLASSIC_SIDE_PADDING, MOBILE_SIDE_PADDING
  classicVerticalPadding = 'classicVerticalPadding', // CLASSIC_VERTICAL_PADDING, MOBILE_VERTICAL_PADDING
  gridSidePadding = 'gridSidePadding', // GRID_SIDE_PADDING, MOBILE_SIDE_PADDING
  gridVerticalPadding = 'gridVerticalPadding', // GRID_VERTICAL_PADDING, MOBILE_VERTICAL_PADDING
  mobileServiceDetailsFontSize = 'mobileServiceDetailsFontSize', // MOBILE_OFFERING_DETAILS_FONT_SIZE
  mobileBookButtonFontSize = 'mobileBookButtonFontSize', // MOBILE_BUTTON_TEXT_FONT_SIZE
  bookButtonBorderWidth = 'bookButtonBorderWidth', // BUTTON_BORDER_WIDTH
  bookButtonCornerRadius = 'bookButtonCornerRadius', // BUTTON_CORNER_RADIUS
  serviceImageCornerRadius = 'serviceImageCornerRadius', // SERVICE_IMAGE_CORNER_RADIUS
  serviceImagePadding = 'serviceImagePadding', // SERVICE_IMAGE_PADDING
  mobileExplorePlansFontSize = 'mobileExplorePlansFontSize', // MOBILE_EXPLORE_PLANS_FONT_SIZE
  loadServicesButtonBorderRadius = 'loadServicesButtonBorderRadius', // LOAD_SERVICES_BUTTON_CORNER_RADIUS
  loadServicesButtonBorderWidth = 'loadServicesButtonBorderWidth', // LOAD_SERVICES_BUTTON_BORDER_WIDTH
  maxCardsPerRow = 'maxCardsPerRow',
  cardSpacing = 'cardSpacing',
  gridCardsSpacing = 'gridCardsSpacing',
  stripCardSpacing = 'stripCardSpacing',
  servciesDividerWidthInStrip = 'servciesDividerWidthInStrip',
}

export enum IStylesParamsBooleanKeys {
  isServiceImageVisible = 'isServiceImageVisible',
  isTitleVisible = 'isTitleVisible',
  isServiceDividerVisible = 'isServiceDividerVisible',
  isOnlineBadgeVisible = 'isOnlineBadgeVisible',
  isTagLineVisible = 'isTagLineVisible',
  isMoreInfoButtonVisible = 'isMoreInfoButtonVisible',
  isServiceOfferedDaysVisible = 'isServiceOfferedDaysVisible',
  isServiceStartDateVisible = 'isServiceStartDateVisible',
  isCourseAvailabilityVisible = 'isCourseAvailabilityVisible',
  isServiceDurationVisible = 'isServiceDurationVisible',
  isServicePriceVisible = 'isServicePriceVisible',
  isBookButtonVisible = 'isBookButtonVisible',
  isExplorePlansVisible = 'isExplorePlansVisible',
  alternateImageInfoSides = 'alternateImageInfoSides',
}

type IStylesParamsColors = {
  [key in `${IStylesParamsColorsKeys}`]: StyleParamType.Color;
};

type IStylesParamsFonts = {
  [key in `${IStylesParamsFontsKeys}`]: StyleParamType.Font;
};

type ILegacyStylesParamsFonts = {
  [key in `${ILegacyStylesParamsFontsKeys}`]: StyleParamType.Font;
};

type IStylesParamsNumbers = {
  [key in `${IStylesParamsNumbersKeys}`]: StyleParamType.Number;
};

type IStylesParamsBooleans = {
  [key in `${IStylesParamsBooleanKeys}`]: StyleParamType.Boolean;
};

const presetId = createStylesParam('presetId', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export type IStylesParams = IStylesParamsColors &
  IStylesParamsFonts &
  ILegacyStylesParamsFonts &
  IStylesParamsNumbers &
  IStylesParamsBooleans & {
    presetId: StyleParamType.Number;
    bookButtonBorderRadius: StyleParamType.Number; // new settings corner radius
  };

const mobileBookButtonFontSize = createStylesParam('mobileBookButtonFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
});

const bookButtonBorderColor = createStylesParam('bookButtonBorderColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
});

const bookButtonBackgroundColor = createStylesParam(
  'bookButtonBackgroundColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
);

const fillBookButtonTextColor = createStylesParam('fillBookButtonTextColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1'),
});

const booleanParameter = (defaultValue: boolean) =>
  ({
    type: StyleParamType.Boolean,
    getDefaultValue: () => defaultValue,
  } as const);

const stylesParams = createStylesParams<IStylesParams>({
  presetId: presetId as any,

  isServiceImageVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () =>
      undefined as unknown as boolean,
  },
  isTitleVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue }) => {
      const preset = getPresetId(getStyleParamValue);
      switch (preset) {
        case BOOKINGS_MAIN_PAGE_PRESET_ID:
        case BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID:
          return true;
        default:
          return false;
      }
    },
  },
  isServiceDividerVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue, isMobile }) => {
      if (isMobile) {
        return true;
      }
      const preset = getPresetId(getStyleParamValue);
      switch (preset) {
        case SINGLE_SERVICE_PRESET_ID:
        case SINGLE_SERVICE_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isOnlineBadgeVisible: booleanParameter(false),
  isTagLineVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue, isMobile }) => {
      if (isMobile) {
        return true;
      }
      const preset = getPresetId(getStyleParamValue);
      switch (preset) {
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
        case GRID_PRESET_ID:
        case GRID_EDITOR_X_PRESET_ID:
        case BOOKINGS_MAIN_PAGE_PRESET_ID:
        case BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID:
        case SINGLE_SERVICE_PRESET_ID:
        case SINGLE_SERVICE_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isMoreInfoButtonVisible: booleanParameter(false),
  isServiceOfferedDaysVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue, isMobile }) => {
      if (isMobile) {
        return true;
      }
      const preset = getPresetId(getStyleParamValue);
      switch (preset) {
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isServiceStartDateVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue, isMobile }) => {
      if (isMobile) {
        return true;
      }
      const preset = getPresetId(getStyleParamValue);
      switch (preset) {
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isCourseAvailabilityVisible: booleanParameter(true),
  isServiceDurationVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue, isMobile }) => {
      if (isMobile) {
        return true;
      }
      const preset = getPresetId(getStyleParamValue);
      switch (preset) {
        case CLASSIC_PRESET_ID:
        case CLASSIC_EDITOR_X_PRESET_ID:
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isServicePriceVisible: booleanParameter(true),
  isBookButtonVisible: booleanParameter(true),
  isExplorePlansVisible: booleanParameter(true),

  widgetBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  widgetTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 32, htmlTag: 'h1' }),
  },
  mobileWidgetTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  widgetTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  cornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  filterTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  titleAlignment: {
    type: StyleParamType.Font,
    getDefaultValue: () =>
      buildStringStyleParams(undefined as unknown as AlignmentOptions),
  },
  filterTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  serviceTextAlignment: {
    type: StyleParamType.Font,
    getDefaultValue: (): stringStyleParams<AlignmentOptions> =>
      buildStringStyleParams(undefined as unknown as AlignmentOptions),
  },
  mobileFilterTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  filterSelectedIndicationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  filterSelectedTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  filterSelectedBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  filterHoverIndicationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  filterHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  filterHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  filterBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  filterBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  filterBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  filterBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  filterSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
  dropdownTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  dropdownBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.4),
  },
  dropdownBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  dropdownBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  dropdownTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  serviceImageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  maxCardsPerRow: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: (): number => undefined as unknown as number,
  },
  cardSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: (): number => undefined as unknown as number,
  },
  gridCardsSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: (): number => undefined as unknown as number,
  },
  stripCardSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: (): number => undefined as unknown as number,
  },
  overlappingSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  overlappingVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  stripBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  stripSidePadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  stripVerticalPadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 32),
  },
  classicSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: (): number => 60,
  },
  classicVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: (): number => 60,
  },
  gridSidePadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 32),
  },
  gridVerticalPadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 32),
  },
  imageAndTextRatio: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) => {
      const preset = getPresetId(getStyleParamValue);

      switch (preset) {
        case CLASSIC_PRESET_ID:
        case CLASSIC_EDITOR_X_PRESET_ID:
          return Number(ImageAndTextRatioOptions.RATIO_40_60);
        default:
          return Number(ImageAndTextRatioOptions.RATIO_50_50);
      }
    },
  },
  servicesDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  serviceNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 24, htmlTag: 'h2' }),
  },
  serviceNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileServiceNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  serviceTagLineFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16, htmlTag: 'p' }),
  },
  serviceTaglineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileServiceTaglineFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  moreInfoButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  moreInfoButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  mobileMoreInfoButtonFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  serviceDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: (): number => 1,
  },
  servciesDividerWidthInStrip: {
    type: StyleParamType.Number,
    getDefaultValue: (): number => 1,
  },
  serviceDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  serviceDetailsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  serviceDetailsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16, htmlTag: 'p' }),
  },
  courseAvailabilityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  courseAvailabilityFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16, htmlTag: 'p' }),
  },
  mobileServiceDetailsFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  bookButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  mobileBookButtonFontSize,
  bookButtonBackgroundColor,
  bookButtonHoverBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue }) => {
      const backgroundColor: ISettingsColor = getStyleParamValue(
        bookButtonBackgroundColor,
      );
      return { ...backgroundColor, opacity: backgroundColor.opacity * 0.7 };
    },
  },
  holeBookButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  fillBookButtonTextColor,
  bookButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue }) => {
      const textColor: ISettingsColor = getStyleParamValue(
        fillBookButtonTextColor,
      );
      return { ...textColor, opacity: textColor.opacity * 0.7 };
    },
  },
  bookButtonBorderColor,
  bookButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue(bookButtonBorderColor),
  },
  bookButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  bookButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  bookButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  explorePlansTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  explorePlansFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  mobileExplorePlansFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue(mobileBookButtonFontSize) ?? 14,
  },
  serviceImageCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  serviceImagePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  fillLoadServicesButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  loadServicesButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  loadServicesButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  loadServicesButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  loadServicesButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  loadServicesButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  serviceImageShape: {
    type: StyleParamType.Font,
    getDefaultValue: () =>
      buildStringStyleParams(undefined as unknown as ImageShapeOptions),
  },
  serviceListLayout: {
    type: StyleParamType.Font,
    getDefaultValue: () =>
      buildStringStyleParams(undefined as unknown as ServiceListLayoutOptions),
  },
  serviceImagePosition: {
    type: StyleParamType.Font,
    getDefaultValue: () =>
      buildStringStyleParams(undefined as unknown as ImagePositionOptions),
  },
  alternateImageInfoSides: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined as unknown as boolean,
  },
  serviceImageSize: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: () =>
      buildStringStyleParams(undefined as unknown as ImageSizeOptions),
  },
  /* legacy keys app settings*/
  OFFERING_NAME_FONT: {
    type: StyleParamType.Font,
  },
  CATEGORY_NAME_FONT: {
    type: StyleParamType.Font,
  },
  MULTI_OFFERINGS_TITLE_FONT: {
    type: StyleParamType.Font,
  },
  MOBILE_CATEGORY_NAME_FONT: {
    type: StyleParamType.Font,
  },
  COURSE_AVAILABILITY_FONT: {
    type: StyleParamType.Font,
  },
  OFFERING_TAGLINE_FONT: {
    type: StyleParamType.Font,
  },
  OFFERING_MORE_INFO_LABEL_FONT: {
    type: StyleParamType.Font,
  },
  OFFERING_DETAILS_FONT: {
    type: StyleParamType.Font,
  },
  BUTTON_TEXT_FONT: {
    type: StyleParamType.Font,
  },
  EXPLORE_PLANS_FONT: {
    type: StyleParamType.Font,
  },

  /* end legacy keys app settings*/
});

export default stylesParams;

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = (args) => {
  const { styleParams, tpaData, isMobile } = args;
  const displayStyle = (booleanStyleParam: VisibilityKey) =>
    styleParams.booleans[booleanStyleParam] ? 'block' : 'none';
  const isServiceImageVisible =
    styleParams.booleans.isServiceImageVisible ??
    getIsServiceImageVisibleFromTpaDate(tpaData, isMobile);

  return {
    serviceImageDisplay: isServiceImageVisible ? 'block' : 'none',
    isServiceImageVisible: isServiceImageVisible ? 1 : 0,
    titleDisplay: displayStyle('isTitleVisible'),
    serviceDividerDisplay: displayStyle('isServiceDividerVisible'),
    onlineBadgeDisplay: displayStyle('isOnlineBadgeVisible'),
    tagLineDisplay: displayStyle('isTagLineVisible'),
    moreInfoButtonDisplay: displayStyle('isMoreInfoButtonVisible'),
    serviceOfferedDaysDisplay: displayStyle('isServiceOfferedDaysVisible'),
    serviceStartDateDisplay: displayStyle('isServiceStartDateVisible'),
    courseAvailabilityDisplay: displayStyle('isCourseAvailabilityVisible'),
    serviceDurationDisplay: displayStyle('isServiceDurationVisible'),
    servicePriceDisplay: displayStyle('isServicePriceVisible'),
    bookButtonDisplay: displayStyle('isBookButtonVisible'),
    explorePlansDisplay: displayStyle('isExplorePlansVisible'),
    ...generateAllCssVars(args),
  };
};
